<template>
  <div>
    <b-row>
      <b-col lg="12" md="12" sm="12">
        <b-card>
          <div
            class="d-lg-flex d-md-flex justify-content-between align-items-center"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <h5 class="bold d-inline">Your are onboard.</h5>
                    <span class="ml-1">Happy Working</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-lg-0 mt-md-0 mt-1">
              <b-button v-b-modal.submit-modal variant="outline-primary">
                Submit & mark as work done
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col>
        <b-col lg="4" md="6" sm="12">
          <AnnualSalary
            title="Annual Salary"
            payment-cycle="Hourly"
            compensation="100"
            tenure="12 March, 2022 - 23 March 2023"
          />
        </b-col>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" md="12" sm="12">
        <b-card>
          <div
            class="d-lg-flex d-md-flex justify-content-between align-items-center"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-2">
                <b-avatar
                  size="lg"
                  src="../../assets/images/avatars/1-small.png"
                />
              </div>
              <div>
                <p class="my-0">Assignment Manager</p>
                <h5 class="d-inline">Generic Name</h5>
                <span> is available to solve your queries</span>
              </div>
            </div>
            <div class="mt-lg-0 mt-md-0 mt-1">
              <b-button variant="outline-primary">
                Have a Conversation
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="12" lg="12" class="pt-2 mt-0">
        <!-- assignment title component -->
        <AssignmentTitle />
      </b-col>
      <b-col sm="12" md="12" lg="12" class="pt-2 mt-0">
        <!-- assignment title component -->
        <TermsAndCondition />
      </b-col>
    </b-row>

    <!-- Submit & mark as work done modal  -->
    <b-modal
      id="submit-modal"
      centered
      title="Submit & mark as work done"
      cancel-title="Close"
      ok-only
      size="lg"
      ok-title="Submit"
      @ok=""
    >
      <b-row class="">
        <b-col lg="12" md="10" sm="10" class="mx-auto">
          <div class="px-lg-5">
            <b-form-group label="Add Invoice" class="my-1">
              <b-form-file v-model="invoice" selected-variant="primary" />
            </b-form-group>

            <b-row class="d-flex justify-content-between">
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="Start Date" class="mb-1">
                  <b-form-datepicker
                    v-model="startDate"
                    class=""
                    locale="en"
                    @context=""
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="End Date" class="mb-1">
                  <b-form-datepicker
                    v-model="endDate"
                    class=""
                    locale="en"
                    @context=""
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-between">
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="Start Time" class="mb-1">
                  <b-form-timepicker
                    v-model="startTime"
                    class=""
                    locale="en"
                    @context=""
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="End Time" class="mb-1">
                  <b-form-timepicker
                    v-model="endTime"
                    class=""
                    locale="en"
                    @context=""
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Add Note" class="my-1">
              <b-form-textarea
                v-model="addNote"
                selected-variant="primary"
                placeholder="write here"
                rows="3"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BRow,
  BFormGroup,
  BFormTimepicker,
  BFormDatepicker,
  BFormTextarea,
  BFormInput,
  BFormFile,
} from 'bootstrap-vue';
import AnnualSalary from './AnnualSalary.vue';
import AssignmentTitle from './AssignmentTitle.vue';
import HourlyCompensation from './HourlyCompensation.vue';
import Requirements from './Requirements.vue';
import TermsAndCondition from './TermsAndCondition.vue';

export default {
  components: {
    BRow,
    BCard,
    BAvatar,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTimepicker,
    BFormDatepicker,
    BFormTextarea,
    BFormFile,
    Requirements,
    HourlyCompensation,
    AssignmentTitle,
    TermsAndCondition,
    AnnualSalary,
  },

  data() {
    return {
      invoice: null,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      addNote: null,
    };
  },
  computed: {},
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
</style>

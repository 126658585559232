<template>
  <b-card class="my-2">
    <div class="mx-1 mb-1 d-flex justify-content-between align-items-center">
      <h4 class="bold">
        {{ title }}
      </h4>
      <b-button size="sm" variant="outline-primary" pill> Full Time </b-button>
    </div>
    <b-row class="mx-1">
      <b-col class="border rounded-lg px-2 py-1">
        <div class="mb-1">
          <h6 class=" ">Payment Cycle</h6>
          <h5 class="bold">
            {{ paymentCycle }}
          </h5>
        </div>
        <div class="mb-1">
          <h5 class=" ">Compention</h5>
          <h4 class="bold">${{ compensation }}</h4>
        </div>
        <div class="mb-1">
          <h6 class=" ">Tenure</h6>
          <h5 class="bold">
            {{ tenure }}
          </h5>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { BButton, BCard, BCol, BRow } from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCol,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    paymentCycle: {
      type: String,
      required: true,
    },
    compensation: {
      type: [Number, String],
      required: true,
    },
    tenure: {
      type: String,
      required: true,
      default: '',
    },
  },
};
/* eslint-disable global-require */
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
